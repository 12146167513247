.container {
    @media (max-width: 991px) {
        padding: 16px 0px;
    }
    padding: 24px 0px;
    width: 100%;
}
.empty {
    height: 1px;
}
.fill {
    height: 1px;
}
.navbarPadding {
    padding: 0px;
    @media (max-width: 991px) {
        padding: 0px;
    }
}
