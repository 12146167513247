@import '@/assets/scss/mixins';
$sidePadding: 40px;

.root {
    position: absolute;
    width: 100vw;
    height: 100vh;
    padding: 100px $sidePadding;
    top: 0;
    left: 0;
    right: 0;
    color: #0d0c0b;
    transition: opacity 0.8s ease-out, max-height 0.8s ease-out;
    max-height: 1000px;
}

.root:not(.inDom) {
    display: none;
}

.contentWrapper {
    display: flex;
    margin-top: 32px;
    padding-right: 35px;
    max-height: calc(100% - 120px);
    overflow-y: scroll;

    .contentAction {
        position: fixed;
        bottom: 40px;
        display: flex;
        align-items: center;
    }
}

.section1,
.section2 {
    z-index: 10000000;
    position: fixed;
    bottom: 20px;
    width: calc(100% - $sidePadding * 2);
    max-height: 756px;
    padding: $sidePadding 200px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    background-color: var(--core85);

    // ANIMATION
    opacity: 0;
    visibility: hidden;
    transform: translateY(50px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out, visibility 0.2s ease-out;
}

.isOpen .section2 {
    top: 80px;
    opacity: 1;
    visibility: visible;
}

.isClose .section1 {
    bottom: 20px;
    opacity: 1;
    visibility: visible;
}

.accept,
.settingsBtn {
    cursor: pointer;

    i {
        transition: transform 0.3s ease, text-decoration 0.3s ease;
    }

    &:hover {
        i {
            transform: translateX(4px);
        }
    }

    &:not(:hover) {
        i {
            transform: translateX(0px);
        }
    }
}

.settingsBtn {
    letter-spacing: -0.4px;
}

.accept {
    display: flex;
    padding: 12px 24px;
    margin-left: 24px;
    align-items: center;
    justify-content: space-between;
    border: 2px solid transparent;
    border-radius: 25px;
    gap: 4px;
    .acceptContent {
        letter-spacing: -0.2px;
    }
}

.acceptance {
    margin-top: 32px;

    .action {
        display: flex;
        align-items: center;
        min-width: 100%;
        margin-top: 32px;

        .icon {
            font-size: 17px !important;
            padding-left: 0;
        }
    }
}

.close {
    min-width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;

    .icon {
        font-size: 12px !important;
        cursor: pointer;
        z-index: 100;
    }
}

.hidden {
    opacity: 0;
}

@include screen('large') {
    $sidePadding: 64px;
    .root {
        padding: 100px $sidePadding;
    }
    .section1,
    .section2 {
        padding: $sidePadding 64px;
    }
    .acceptance {
        .action {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            .accept {
                order: 0;
                margin-left: 0;
            }
            .settingsBtn {
                order: 3;
                margin-left: 24px;
            }
        }
    }
    .isOpen {
        .section1,
        .section2 {
            top: 14px;
        }
    }
    .isClose {
        .section1,
        .section2 {
            bottom: 20px;
        }
    }
    .section1,
    .section2 {
        padding: $sidePadding 64px;
        width: calc(100% - $sidePadding * 2);
        max-height: none;
    }
}

@include screen('small') {
    $sidePadding: 20px;
    .root {
        padding: 0 $sidePadding;
    }
    .root.isOpen {
        padding: 0 0 !important;
    }

    .contentWrapper {
        margin-top: 48px;

        .contentAction {
            background-color: var(--core85);

            display: flex;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 40px);
            bottom: 15px;
        }
    }
    .contentAction {
        padding-left: 24px;
    }

    .section1,
    .section2 {
        width: calc(100% - $sidePadding * 2) !important;
        padding: 20px !important;
    }

    .isOpen {
        .section1,
        .section2 {
            background: linear-gradient(0deg, var(--core85), var(--core85) 85px, var(--core90) 85px, var(--core90));
            min-width: 100%;
            padding: 20px;
            top: 0;
            bottom: 0;
        }
    }
    .isClose {
        padding: 0 20px;

        .section1,
        .section2 {
            bottom: 20px;
        }
    }
}

@keyframes slideFadeInSection1 {
    0% {
        transform: translateY(50px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideFadeOutSection1 {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(50px);
    }
}

@keyframes slideFadeInSection2 {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideFadeOutSection2 {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-50px);
    }
}
@keyframes slideFadeOutRoot {
    0% {
        transform: translateY(80px);
    }
    100% {
        transform: translateY(500px);
    }
}

.isClose .section1 {
    animation: slideFadeInSection1 0.8s forwards;
    visibility: visible;
}

.isOpen .section2 {
    animation: slideFadeInSection2 0.8s forwards;
    visibility: visible;
}

.isClose .section2 {
    animation: slideFadeOutSection2 0.8s forwards;
}

.isOpen .section1 {
    animation: slideFadeOutSection1 0.8s forwards;
}

.root.hidden {
    animation: slideFadeOutRoot 0.8s forwards;
}
