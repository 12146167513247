@import '../Common.module.scss';

.iconWrapper {
    display: flex;
    justify-content: flex-end;
}

.root {
    display: flex;
    justify-content: center;
    padding-top: 96px !important;
}

.container {
    display: flex;
    flex-wrap: wrap;
}
.content {
    min-width: 100%;
    padding-bottom: 64px;
}

.menuItem {
    border-radius: 0px;
    padding: 16px 20px 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.menuColor {
    color: var(--core40);
}
.menuColorDark {
    color: var(--core60);
}
.activeColor {
    color: var(--core5);
}
.activeColorDark {
    color: var(--core95);
}
