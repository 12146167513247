@import 'assets/scss/_mixins.scss';
.root {
    position: fixed;
    top: 88px;
    color: var(--core60);
    background-color: var(--core10);
    width: 100%;
    font-weight: $font-weight-book; //p compressed
    font-size: 16px !important;
    letter-spacing: -0.2px;
    line-height: 22px;
    z-index: 10;
    transition: opacity 0.6s ease;
    box-shadow: 0px -1px 0px 0px #332e2e inset;
}
.link {
    min-width: fit-content;
    cursor: pointer;
    a {
        font-size: 16px;
    }
}

.menu {
    display: flex;
    scrollbar-width: none;
}
.wrapper {
    max-height: 70px;
    display: flex;
    align-items: center;
    position: relative;
}
