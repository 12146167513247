@import 'assets/scss/_mixins.scss';

.root {
    position: relative;
    background-color: var(--core5);
    color: var(--core80);
    padding-bottom: 128px;
}
.linkColor {
    color: var(--core80);
}
.linkSpacing {
    padding-top: 8px;
}
.directContact {
    a {
        text-decoration-line: underline;
        &:hover {
            color: var(--core95);
        }
    }
}
.contact {
    margin-top: 48px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.2px;
    .info {
        margin-top: 24px;
        a {
            margin-top: 3px;
            display: flex;
            text-decoration: underline;
        }
    }
}

@include screen('medium') {
    .contact {
        padding: 0 20px;
    }
}
