@import '@/assets/scss/mixins.scss';

.root {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    padding-top: 64px;

    margin: 0 auto;
}
.skeleton {
    margin: auto;
}
.info {
    margin-top: 20px;
}

.wrapper {
    height: 424px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--core80);
    padding: 10px;
}
.icon {
    text-align: end;
    color: var(--core20);
    padding: 0 5px 5px 0;
}

.isDark {
    .wrapper {
        border-color: var(--core20);
    }
    .icon {
        color: var(--core80);
    }
}
