@import '@/assets/scss/mixins.scss';

.dark.root {
    .icon {
        color: var(--core60);
    }
    .button {
        color: var(--core80);
    }
    .role,
    .location {
        color: var(--core60);
    }
    .row {
        border-top: 1px solid var(--core20);
        background-color: var(--core5);
        // border-right: 1px solid var(--core20);
        // border-left: 1px solid var(--core20);
        &:hover {
            background-color: var(--core10);
            .role,
            .location,
            .icon {
                color: var(--core95);
            }
        }
        &:active {
            background-color: var(--core15);
            .role,
            .location,
            .title,
            .icon {
                color: var(--core80);
            }
        }
    }
    .lastRow {
        border-bottom: 1px solid var(--core20);
        margin-bottom: 32px;
    }
}
.root {
    padding-top: 64px;
    .icon {
        color: var(--core60);
    }
    .title {
        margin-bottom: 4px;
    }
    .row {
        cursor: pointer;
        width: 100vw;
        background-color: var(--core95);
        padding: 24px 0;
        border-top: 1px solid var(--core80);
        &:hover {
            background-color: var(--core90);
        }
        &:active {
            background-color: var(--core80);
            .role,
            .location,
            .title,
            .icon {
                color: var(--core5);
            }
        }
    }
    .lastRow {
        border-bottom: 1px solid var(--core80);
        margin-bottom: 32px;
    }
    .role,
    .location,
    .icon {
        color: var(--core40);
    }
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
    }

    .button {
        color: var(--core40);
        font-size: 20px;
        font-weight: $font-weight-book; //p compressed
        line-height: 24px;
        letter-spacing: -0.2px;
        margin: auto;

        border-radius: 25px;
        border: 2px solid transparent;
        padding: 12px 24px;
        width: fit-content;
        cursor: pointer;
    }
}

.skeletonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@include screen('medium') {
    .root {
        margin: auto;
        .wrapper {
            width: 100%;
            max-width: 100%;
        }
        .row {
            padding: 24px 20px;
        }
    }
}
