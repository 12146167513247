@import '@/assets/scss/mixins.scss';

.dark.root {
    .button {
        color: var(--core80);
    }
    .closeBtn {
        color: var(--core20);
    }
}
.root {
    padding: 0 20px;
    padding-top: 64px;
    .row {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        margin-bottom: 24px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        grid-auto-flow: dense;
        transition: all 300ms;
        max-width: 1192px;
    }

    .button {
        color: var(--core40);
        font-size: 20px;
        font-weight: $font-weight-book;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin: auto;

        border-radius: 25px;
        border: 2px solid transparent;
        padding: 12px 24px;
        width: fit-content;
        cursor: pointer;
    }
}
@include screen('small') {
    .root {
        .row {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
