@import '@/assets/scss/mixins';

.header {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.title {
    i {
        font-size: 30px;
    }
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 32px 0;
    width: 100%;
}
.description {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.2px;
}
.arrow {
    transform: rotate(180deg);
}

.hidden {
    display: none;
}

@include screen('small') {
    .title {
        margin-top: 48px;
        margin-bottom: 32px;
    }
    .description {
        font-size: 16px;
        line-height: 22px;
    }
}
