.root {
    color: var(--primaryTextColor);
    min-height: 100vh;
    padding-top: 85px;
    background-color: var(--standardBackgroundLight);

    &.existsNavbar {
        padding-top: 158px;
    }
    &.darkTheme {
        background-color: transparent;
    }

    @media screen and (max-width: 860px) {
        padding-top: 0 !important;
    }
}
