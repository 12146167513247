@import '@/assets/scss/mixins';

.toogle {
    position: relative;
    width: 32px;
    height: 22px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    z-index: 100;
    border: 2px solid var(--core5);

    &.selected {
        background-color: var(--core5);
        color: var(--core95);
    }
    &.disabled {
        background-color: var(--core60);
        color: var(--core40);
        border-color: var(--core60);
    }
}

.toogle,
.circle,
.arrow {
    transition: all 0.3s ease-in-out;
}

.toogle:not(.selected) .circle {
    left: 4px;
    position: absolute;
    font-size: x-small !important;
}

.toogle:not(.selected) .arrow {
    position: absolute;
    right: 4px;
    font-size: x-small !important;
}

.selected {
    .circle {
        right: 4px;
        position: absolute;
        font-size: x-small !important;
    }

    .arrow {
        position: absolute;
        transform: rotate(180deg);
        left: 4px;
        font-size: x-small !important;
    }
}
