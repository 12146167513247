@import '@/assets/scss/mixins';
.menuItem {
    cursor: pointer;

    .dark & {
        color: var(--core60);
        background-color: var(--core5);
        border: 1px solid var(--core20);

        &:hover {
            background-color: var(--core10);
            color: var(--core60);
        }
        &:active {
            background-color: var(--core15);
            color: var(--core95);
        }
        &.active {
            background-color: var(--core10);
            color: var(--core95);
        }
        .disabled {
            cursor: not-allowed;
            background-color: transparent;
            color: var(--core60);
        }
    }
    .light & {
        border: 1px solid var(--core80);
        color: var(--core60);

        &:hover {
            background-color: #e1e3e6;
        }
        &:active {
            background-color: #d4d6d9;
            color: var(--core5);
        }
        &.active {
            background-color: #e1e3e6;
            color: var(--core5);
        }
        .disabled {
            cursor: not-allowed;
            background-color: transparent;
        }
    }
}
