@import 'assets/scss/_mixins.scss';

.button {
    cursor: pointer;
    background-color: var(--core5);
    color: var(--core80);
    transition: all 150ms ease-in-out;
    &:hover {
        background-color: var(--core10);
        color: var(--core95);

        i {
            transform: translateX(6px);
        }
    }
    &:active {
        background-color: var(--core15);
        color: var(--core95);
    }
    text-align: left;
    min-width: 100%;
    border-radius: 12px;
    padding: 24px;
    font-size: 20px;
    letter-spacing: -0.4px;
    display: flex;

    .btnContent {
        min-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
            transition: all 150ms ease-in-out;
        }
    }
}
.btnContent {
    .btnText {
        color: var(--core80);
        word-break: break-word;
    }
    .lightText {
        color: var(--core20);
    }
}

.button.dark {
    background-color: var(--core95);
    color: var(--core80);
    &:hover {
        background-color: var(--core90);
        color: var(--core5);
    }
    &:active {
        background-color: var(--core85);
        color: var(--core5);
    }
}
