@import '@/assets/scss/mixins';

.link {
    user-select: none;
    line-height: 26px;
    margin-right: 24px;
    position: relative;
    z-index: 100;
    padding: 24px 0;
    width: fit-content;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
}

.link:hover,
.link.active {
    color: var(--core95);
}

.link.hover::before {
    opacity: 1;
}

.rtl {
    line-height: 26px;
}
@include screen('large') {
    .link {
        display: flex;
        justify-content: space-between;
        line-height: 24px;
        font-variant-numeric: lining-nums tabular-nums;
        font-feature-settings: 'dlig' on, 'ss03' on;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: 20px;
        font-weight: $font-weight-book; //small dark
        letter-spacing: -0.4px;
        cursor: pointer;
        width: 100%;

        a {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}
