$duration: 250ms;
.container {
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(13, 12, 11, 0.72);
        justify-content: center;
        align-items: center;
        z-index: 200;
    }
    .top {
        position: fixed;
        z-index: 100;
        width: 100vw;
        display: flex;
        flex-direction: column;
        padding: 16px 24px 24px 24px;
        gap: 24px;
        background-color: var(--core95);
    }
    .progress {
        background-color: rgb(88, 90, 90);
    }
    .chapterNav {
        position: fixed;
        z-index: 100;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 44px 20px 20px 20px;
        left: 0;

        .close,
        .chapt {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 48px;
            padding: 20px;
            background-color: var(--core80);
            border-radius: 8px;
            gap: 12px;
            animation: button-expand $duration ease-in-out;
            i {
                font-size: 16px;
                color: var(--core40);
            }
        }
        .chapters {
            width: 19px;
            height: 16px;
        }
        .minimized {
            height: 48px;
            width: 48px;
            display: flex;
            align-content: center;
            justify-content: center;
            animation: button-shrink $duration ease-in-out;
        }

        .secondarynav {
            display: flex;
            flex-direction: column;

            width: calc(100vw - 40px);
            border-radius: 8px;
            background-color: var(--core80);
            padding: 12px 20px;

            i {
                font-size: 16px;
            }

            .title {
                color: var(--core5);
            }
            .active {
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 7px;
                text-decoration-color: var(--color);
            }
            .row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: 54px;
                .group {
                    display: flex;
                    flex-direction: row;
                    gap: 12px;
                }
                i {
                    height: 16px;
                }
            }
        }
    }
    .closeRight {
        justify-content: flex-end;
    }
}

.text {
    animation: text-expand $duration ease-in-out;
}

@keyframes button-expand {
    from {
        max-width: 48px;
    }
    to {
        max-width: calc((100vw) / 3);
    }
}
@keyframes text-expand {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes button-shrink {
    from {
        width: calc((100vw - 40px) / 3);
    }
    to {
        width: 48px;
    }
}
