@import '../../assets/scss/mixins';
.root {
    padding: 4vw 0;
    border-color: var(--core10) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    @media screen and (max-width: 1300px) {
        padding: 0;
    }

    :global(.swiper) {
        overflow: hidden;
    }
    :global(.swiper-wrapper) {
        display: flex;
    }
}

.successSlide {
    min-height: 649px;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wrap {
    width: 100%;
    display: flex;
    max-width: 1250px !important;

    .rightSide,
    .leftSide {
        max-width: 50%;
        transition: background-color 0.1s ease;
    }

    .rightSide {
        position: relative;
        padding: 96px 48px 48px 48px;
        background-color: var(--core10);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .leftSide {
        display: flex;
        flex-direction: column;
        justify-content: center;

        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 48px;
    }

    .infoTitle {
        @include style-font(60px, 64px, -2px); //typography h1
        margin-bottom: 96px;
    }
    .infoSubtitle {
        margin-bottom: 24px;
        font-weight: 700;
    }
    .description {
        font-weight: 220;
    }
}
.infoWrapper {
    display: flex;
}
.inputWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.closeIcon {
    cursor: pointer;
    position: absolute;
    top: 48px;
    right: 48px;
    color: var(--core80);
}

@media screen and (max-width: 1160px) {
    .root {
        display: static;
        height: 100%;
        padding: 0;
    }
    .layout {
        margin: 0 auto;
        max-width: 600px;
    }
    .wrap,
    .leftSide,
    .rightSide {
        min-height: 100%;
        max-width: 100%;
        min-width: 100%;
        border-radius: 0 !important;
    }

    .successSlide {
        min-height: max-content;
    }
    .wrap {
        flex-direction: column;
        .leftSide {
            order: 2;
            padding: 64px 0 156px 0px;
            min-height: 50vh;
        }
        .rightSide {
            order: 1;
            padding: 64px 0 32px 0;
            display: flex;
            align-items: center;
            min-height: 50vh;
        }
        .isSubmitSuccessful.rightSide {
            padding: 0 !important;
            max-height: max-content !important;
            .swiper {
                padding: 32px 0px;
            }
        }

        .infoTitle {
            @include style-font(48px, 54px, -1.2px); //typography h1
            margin-bottom: 32px;
        }
    }
}
@include screen('large') {
    .layout {
        margin: 0 auto;
        width: 434px;
        max-width: 100%;
    }
    .isSubmitSuccessful.rightSide {
        .swiper {
            width: 434px;
            max-width: 100%;
        }
    }
}

@include screen('small') {
    .layout {
        padding: 0 20px;
    }
}
