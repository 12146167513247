@import '@/assets/scss/mixins';
.container {
    position: fixed;
    z-index: 100;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 32px 8px 32px;
    background: var(--core-colors-95, #edeff2);
    top: 89px;
}
.secondary_nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.btn,
.arrowBack {
    cursor: pointer;
    color: var(--core5);
    font-size: 25px;
}
.arrowBack{
    transform: rotate(180deg);
}
.link {
    color: var(--core40);
    cursor: pointer;
    padding-right: 24px;
}
.active {
    color: var(--core5);
}
.progressbar {
    display: flex;
    align-self: center;
    top: 243px;
    width: 880px;
    padding-left: 20px;
    padding-right: 20px;
}
.anchorlinks {
    display: flex;
    width: $largeLayout;
}
